import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import IconSocial from '~/components/atoms/IconSocial'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Team({key, index, ...props}) {

  const section = props.team

  return (
    <section id={section.fieldGroupName + index}  className="my-20 md:my-36 py-20 md:py-36 bg-navy-dark text-white">
      <div className="container">
        <header className="grid grid-cols-12 mb-16">
          <TextCard className="col-span-12 md:col-span-8"  {...section.textCard}   />
        </header>
        <div className="grid grid-cols-12">
          {section.members?.map((member, i) => (
            <FadeInWhenVisible className="col-span-12 sm:col-span-6 xl:col-span-3" key={`team${i}`}>
              <div className="aspect-w-1 aspect-h-1">
                <div className="border border-white border-opacity-20 py-28 px-10 flex flex-col justify-center h-full group">
                  <div className="!absolute inset-0 md:opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out overflow-hidden">
                    <Image data={member.image} className="absolute inset-0 transform scale-100 group-hover:scale-110 transition-transform duration-700 ease-in-out" objectFit="cover" />
                    <div className="absolute inset-0 bg-navy bg-opacity-60"></div>
                  </div>
                  <div className="relative z-10 ">
                    {member.name && <h4 className="text-h4 block group-hover:opacity-40 duration-200 ease-in-out transition-opacity" dangerouslySetInnerHTML={{__html: member.name }}/>}
                    {member.title && <div className="mb-4 group-hover:opacity-40 duration-200 ease-in-out transition-opacity">{member.title}</div>}
                    {member.linkedinUrl && <a href={member.linkedinUrl} className="hover:text-blue group-hover:opacity-40 hover:opacity-100 transition-colors duration-200 ease-in-out"><IconSocial type="linkedin-bg" /></a>}
                  </div>
                </div>
              </div>
            </FadeInWhenVisible>
          ))}
        </div>

      </div>
    </section>
  )
}
